import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { forwardRef } from 'react';

import CloseIcon from '@/assets/icons/ic-close.svg';

import { BREAKPOINTS } from '@/styles/themes/default';
import { IThemeProps } from '@/styles/themes/types';

const Button = styled.button`
  border: none;
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

const Icon = styled(CloseIcon)<ICloseIconProps & IThemeProps>`
  ${({ size }) => {
    if (size === 'small') {
      return css`
        width: 24px;
        height: 24px;
      `;
    }

    if (size === 'regular') {
      return css`
        width: 24px;
        height: 24px;

        @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
          width: 32px;
          height: 32px;
        }
      `;
    }

    if (size === 'large') {
      return css`
        width: 36px;
        height: 36px;

        @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
          width: 50px;
          height: 50px;
        }
      `;
    }

    return '';
  }}

  ${({ variant, theme }) => {
    if (variant === 'dark') {
      return { color: theme.legacy.colors.black };
    }

    if (variant === 'light') {
      return { color: theme.legacy.colors.white };
    }

    return '';
  }}
`;

interface ICloseIconProps {
  size?: 'small' | 'regular' | 'large';
  variant?: 'dark' | 'light';
}

interface ICloseButtonProps extends ICloseIconProps {
  onClose?: () => void;
}

export const CloseButton = forwardRef<HTMLButtonElement, ICloseButtonProps>(function CloseButton(
  { onClose, size = 'regular', variant = 'light', ...restProps },
  ref
) {
  return (
    <Button type="button" onClick={onClose} {...restProps} ref={ref}>
      <Icon size={size} variant={variant} />
    </Button>
  );
});
