import styled from '@emotion/styled';
import { forwardRef } from 'react';

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 100;
  overflow: auto;
`;

/**
 * @deprecated try/adapt the one from shared/core/Overlay
 */
export const Overlay = forwardRef<undefined, any>(({ children, ...restProps }, ref) => {
  return (
    <OverlayContainer ref={ref} {...restProps}>
      {children}
    </OverlayContainer>
  );
});
